import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCalendars(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('calendar', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadCalendarPdf(ctx, { calendarId, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`calendar/${calendarId}/upload`, { params: queryParams, responseType: 'blob' })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    regenerateCalendar(ctx, { calendarId, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`calendar/${calendarId}/generate`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addCalendar(ctx, calendarData) {
      return new Promise((resolve, reject) => {
        axios
          .post('calendar', calendarData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteCalendar(ctx, { calendarId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`calendar/${calendarId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPathways(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('roster/pathways', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPathway(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roster/pathways/${id}`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPathwaySessions(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roster/pathways/${id}/sessions`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPathway(ctx, pathwayData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/roster/pathways', pathwayData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePathway(ctx, { pathwayId, pathwayData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete pathwayData.id;
      delete pathwayData.createdAt;
      delete pathwayData.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`/roster/pathways/${pathwayId}`, pathwayData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSessions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('roster/sessions', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLiveSessions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('roster/sessions/live', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUpcomingSessions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('roster/sessions/upcoming', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSession(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`roster/sessions/${id}`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSessionAttendance(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`roster/attendances`, { params: { ...queryParams, session: id } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSessionAttendanceTable(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`roster/attendances/table/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createSessionClassCheckin(ctx, { sessionId, classId, dateTime }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`roster/attendances/${sessionId}/checkin/${classId}`, { dateTime })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSessionAttendance(ctx, { id, data }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete data.id;
      delete data.createdAt;
      delete data.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`/roster/attendances/${id}`, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSessionRoster(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`roster/sessions/${id}/export`, {
            params: queryParams,
            responseType: 'blob',
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportSessionAttendance(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`roster/attendances/export/${id}`, {
            params: queryParams,
            responseType: 'blob',
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSessionSummary(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`roster/sessions/${id}/summary`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addSession(ctx, { sessionData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/roster/sessions', sessionData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportSessionToMonday(ctx, { sessionId, integrity }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/integration/monday/export', { sessionId, integrity })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSession(ctx, { sessionId, sessionData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete sessionData.id;
      delete sessionData.createdAt;
      delete sessionData.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`/roster/sessions/${sessionId}`, sessionData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addSessionToPathway(ctx, { pathwayId, sessionId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/roster/pathways/${pathwayId}/sessions`, { sessionId })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSessionStudents(ctx, { sessionId, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roster/sessions/${sessionId}/students`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addStudentsToSession(ctx, { sessionId, profileIds, studentIds }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/roster/sessions/${sessionId}/students`, { profileIds, studentIds })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    transferStudentToSession(ctx, { sessionId, profileId, reassignReason }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/roster/sessions/${sessionId}/students`, { profileId, reason: reassignReason })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeStudentsFromSession(ctx, { sessionId, profileIds }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/roster/sessions/${sessionId}/students`, { data: { profileIds } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addCoursesToSession(ctx, { sessionId, courseIds }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/roster/sessions/${sessionId}/courses`, { courseIds })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeCoursesFromSession(ctx, { sessionId, courseIds }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/roster/sessions/${sessionId}/courses`, { data: { courseIds } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCourses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('roster/courses', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCourse(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roster/courses/${id}`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCourseRoster(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`roster/courses/${id}/export`, {
            params: queryParams,
            responseType: 'blob',
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCourseSummary(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roster/courses/${id}/summary`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCourseClasses(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roster/courses/${id}/classes`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateCourse(ctx, { courseId, courseData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete courseData.id;
      delete courseData.createdAt;
      delete courseData.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`/roster/courses/${courseId}`, courseData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    sendCourseBroadcast(ctx, { courseId, broadcast }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/roster/courses/${courseId}/broadcast`, broadcast)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addCourse(ctx, { courseData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/roster/courses', courseData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addStudentsToCourse(ctx, { courseId, studentIds }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/roster/courses/${courseId}/students`, { studentIds })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeStudentsFromCourse(ctx, { courseId, studentIds, profileIds }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/roster/courses/${courseId}/students`, { data: { studentIds, profileIds } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClasses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('roster/classes', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClass(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roster/classes/${id}`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addClass(ctx, { classData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/roster/classes', classData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteClass(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/roster/classes/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editClass(ctx, { id, classData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete classData.id;
      delete classData.createdAt;
      delete classData.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`/roster/classes/${id}`, classData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSubjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('roster/subjects', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSubject(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roster/subjects/${id}`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSubjectWaitlist(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/roster/subjects/${id}/waitlist`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteSubject(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/roster/subjects/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addSubject(ctx, { subjectData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/roster/subjects', subjectData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSubject(ctx, { subjectId, subjectData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete subjectData.id;
      delete subjectData.createdAt;
      delete subjectData.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`/roster/subjects/${subjectId}`, subjectData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    searchProfiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // delete queryParams.filter;

        axios
          .get('roster/profiles/search', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    searchProfilesDownload(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`roster/profiles/search/export`, {
            params: queryParams,
            responseType: 'blob',
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProfiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        // delete queryParams.filter;

        axios
          .get('roster/profiles', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProfile(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`roster/profiles/${id}`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addProfile(ctx, profileData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/roster/profiles', profileData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProfile(ctx, { id, profileData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`roster/profiles/${id}`, profileData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateProfiles(ctx, { ids, profileData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`roster/profiles`, { ids, body: profileData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
